:root {
	--color-background: #fff;
	--color-on-background: #2f2838;
	--color-card-background: #f5f5f5;
	--color-primary: #ED2481;
	--color-on-primary: #fff;
	--color-secondary: #fee7e7;
	--color-on-secondary: #484848;
	--color-error: #b00020;
	--color-on-error: #fff;
	--color-on-hover: rgb(152, 112, 122);
	--color-on-labelAndHeaders: #ED2481;
	--on-active-color: #ffffff;
}

body[data-theme='dark'] {
	--color-background: #2f2838;
	--color-on-background: #fff;
	--color-primary: #bb86fc;
	--color-on-primary: #000;
	--color-secondary: #fee7e7;
	--color-on-secondary: #484848;
	--color-error: #cf6679;
	--color-on-error: #000;
	--on-active-color: #7694ec;
}

body {
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	background: var(--color-background);
	color: var(--color-on-background);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h1 {
	text-align: left;
	color: var(--color-on-labelAndHeaders);
	margin-bottom: 1em;
}

h2 {
	color: var(--color-on-labelAndHeaders);
	font-size: 40px;
	font-weight: 600;
	margin-bottom: 3rem;
	text-align: center;
}

h6 {
	color: var(--color-on-background) !important;
}

h1.long-margin {
	margin-bottom: 1.8em;
}

label {
	text-align: center;
	color: var(--color-on-labelAndHeaders);
}
button {
	background: var(--color-primary);
	color: var(--color-background);
}

input {
	background: var(--color-background);
	color: var(--color-on-background);
	padding: 0.25rem;
}

/** Container **/
.contactForm .container {
	background: var(--color-background);
}

.container .formContainer,
.contactForm .container {
	background: var(--color-background);
}

.contained {
	max-width: 1400px;
}

.container-left {
	padding-left: 15px;
	padding-right: 15px;
	margin-left: calc((100vw - 1400px - 30px) / 2);
}

@media (max-width: 1430px) {
	.container-left {
		margin-left: 0;
	}
}

/** Hero **/
.hero {
	padding-top: 100px;
	padding-bottom: 250px;
}

.hero.hero-small {
	padding-bottom: 40px;
}

.hero h1 {
	color: #000;
	font-size: 50px;
	font-weight: 600;	
	margin-bottom: 3rem;
}

.hero .lead-text {
	font-size: 18px;
	line-height: 1.8;
}

.hero .lead-text:not(:last-child) {
	margin-bottom: 55px;
}

.hero .hero-ctas {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 30px;
}	

@media (max-width: 767px) {
	.hero {
		padding-top: 50px;
		padding-bottom: 100px;
	}

	.hero .hero-ctas {
		flex-direction: column-reverse;
		gap: 30px;
	}
}

/** Sub Section **/
.sub-section {
	margin-bottom: 150px;
}

.sub-section h2 {
	margin-bottom: 4rem;
}

@media (max-width: 767px) {
	.sub-section {
		margin-bottom: 130px;
	}
}